<template>
  <div class="flex flex-row justify-between px-1 pt-1">
    <div class="flex flex-row items-center">
      <ToolbarIconButton icon="ChevronLeftIcon" @click.native="back" />
      <p class="hidden pr-3 ml-2 whitespace-no-wrap md:inline sm:text-lg">{{ title }}</p>
    </div>

    <!-- Middle -->
    <div class="flex flex-row items-center text-lg font-bold">
      <!-- Save to Planner Button -->
      <InfoboxSaveButton @save-click="e => $emit('save-click', e)" />

      <!-- Share Button -->
      <PlannerShareButton ref="socialSharingPopperTarget" @click.native.stop="popper.toggle" />

      <!-- Desktop Read More Button -->
      <ToolbarIconButton
        :icon="readMore ? 'ChevronDownIcon' : 'ChevronUpIcon'"
        :text="readMore ? 'Read Less' : 'Read More'"
        class="hidden mr-1 md:flex"
        :class="[
          isReadyToReadMore
            ? 'ready-to-read-more animate__animated animate__tada animate__repeat-2'
            : null
        ]"
        @click.native="toggleReadMore"
      />

      <!-- Mobile Read More Button -->
      <ToolbarIconButton
        :icon="readMore ? 'ChevronDownIcon' : 'ChevronUpIcon'"
        class="mr-1 md:hidden"
        :class="[
          isReadyToReadMore
            ? 'ready-to-read-more animate__animated animate__tada animate__repeat-2'
            : null
        ]"
        @click.native="toggleReadMore"
      />
    </div>

    <div class="flex flex-row items-center justify-end md:w-32">
      <!-- Close Button -->
      <ToolbarIconButton icon="XIcon" @click.native="close" />
    </div>

    <!-- SocialShare Popper -->
    <div
      v-show="popper.isVisible"
      ref="socialSharingPopper"
      v-click-outside="popper.close"
      class="absolute z-50 w-40 text-center bg-gray-100 dark:bg-gray-900 border border-gray-400 rounded shadow-lg"
    >
      <SocialShare
        :user="$store.state.auth.user"
        :url="currentUrl"
        :title="title || ''"
        :description="description || ''"
        hashtags="rovit"
        twitter-user="rovit"
        @close="popper.close"
      />
    </div>
  </div>
</template>

<script>
import { computed, inject, ref, watch, nextTick } from '@vue/composition-api'
import { canGoBack, back } from '@/use/history.js'
import { usePopper } from '@rovit/popper'
import { SocialShare } from '@rovit/social-share'

import ToolbarIconButton from '../ToolbarIconButton.vue'
import PlannerShareButton from '../PlannerShareButton/PlannerShareButton.vue'
import InfoboxSaveButton from '../InfoboxSaveButton/InfoboxSaveButton.vue'

export default {
  name: 'InfoboxToolbar',
  components: {
    ToolbarIconButton,
    PlannerShareButton,
    InfoboxSaveButton,
    SocialShare
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    }
  },
  setup(props, context) {
    const $route = inject('$route')
    const $router = inject('$router')
    const $store = inject('$store')

    // Social Share Popper
    const socialSharingPopperTarget = ref(null)
    const socialSharingPopper = ref(null)
    const popper = usePopper(socialSharingPopperTarget, socialSharingPopper)

    const currentUrl = window.location.href

    /**
     * Scroll the infobox full screen. Keep visible under toolbar.
     */
    const readMore = ref(false)
    const isReadyToReadMore = ref(true)
    function toggleReadMore() {
      if (readMore.value) {
        scrollUp()
      } else {
        scrollDown.call(this)
      }
      readMore.value = !readMore.value
    }
    // When the infobox changes, reset the `readMore` to false.
    watch(
      () => $store.state.featureLayout.featureItem,
      () => {
        readMore.value = false
        setTimeout(() => (isReadyToReadMore.value = false), 3000)
        setTimeout(() => (isReadyToReadMore.value = true), 3100)
      }
    )

    function scrollUp() {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }
    function scrollDown() {
      const top = this.$parent.$el.offsetTop - 60
      window.scrollTo({
        top,
        left: 0,
        behavior: 'smooth'
      })
    }

    /**
     * Closing an infobox takes you to the Discover page.
     */
    function close() {
      $router.value.push({
        name: 'Discover',
        params: { env: $route.value.params.env }
      })
    }

    return {
      canGoBack,
      back,
      scrollUp,
      close,

      readMore,
      toggleReadMore,
      isReadyToReadMore,

      currentUrl,

      //popper
      socialSharingPopperTarget,
      socialSharingPopper,
      popper
    }
  }
}
</script>

<style lang="postcss" scoped>
.ready-to-read-more {
  @apply text-white bg-gray-500;
}
</style>
