<template>
  <div class="flex flex-row text-white">
    <button
      type="button"
      class="flex flex-row items-center rounded-l-full pl-3 pr-1 py-1 bg-purple-700 border-r border-purple-500 focus:outline-none focus:shadow-outline"
      style="height: 36px"
      @click="e => $emit('save-click', e)"
    >
      <div class="relative">
        <InboxIcon class="mr-2 my-1" />
        <PlusIcon class="absolute top-0 right-0" size="12" />
      </div>
      <p class="hidden sm:inline px-1">Save</p>
    </button>

    <button
      type="button"
      class="flex flex-row items-center rounded-r-full px-3 py-1 bg-purple-700 mr-1.5 focus:outline-none focus:shadow-outline"
      style="height: 36px"
    >
      <FolderIcon size="1x" />
      <ChevronDownIcon size="12" />
    </button>
  </div>
</template>

<script>
import { InboxIcon, FolderIcon, PlusIcon, ChevronDownIcon } from 'vue-feather-icons'

export default {
  name: 'InfoboxSaveButton',
  components: {
    InboxIcon,
    FolderIcon,
    PlusIcon,
    ChevronDownIcon
  }
}
</script>

<style lang="postcss"></style>
