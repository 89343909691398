<template>
  <Dock ref="content" :style="dockStyle" class="z-10 overflow-hidden transition-all duration-500">
    <InfoboxToolbar
      :title="scene && scene.name"
      :description="scene && scene.description"
      class="absolute top-0 w-full"
      @save-click="handleSaveClick"
    />

    <Scene
      v-if="scene && scene.service"
      class="pt-10 overflow-hidden"
      :components="components"
      :scene="scene"
      :service="scene.service"
      :env="env"
      :current-index="currentIndex"
      :additional-props="additionalProps"
      :models="models"
      @activate="handleActivate"
      @link="handleLink"
      @record-event="handleEvent"
    />

    <div
      v-else
      class="flex flex-row items-center justify-center mt-2 text-left"
      style="min-height: 200px"
    >
      Loading Scene
    </div>
  </Dock>
</template>

<script>
import { computed, inject, onMounted, ref, watch } from '@vue/composition-api'
import { models } from 'feathers-vuex'
import { openMiniInfobox } from '@/use/discover.js'
import { getScene } from '@/use/scenes.js'
import { saveToPlanner, addToPlanner } from '@/use/planner-saved-items.js'

import { Dock } from '@rovit/dock'
import { Scene } from '@rovit/scene'
import InfoboxToolbar from '../InfoboxToolbar/InfoboxToolbar.vue'

// SceneModule Components
import { TmServiceInfo } from '@rovit/tm-service-info'
import { TmContactInfo } from '@rovit/tm-contact-info'
import { TmSchedule } from '@rovit/tm-schedule'
import { TmDirections } from '@rovit/tm-directions'
import { TmLocationInfo } from '@rovit/tm-location-info'
import { TmSceneTour } from '@rovit/tm-scene-tour'
import { TmInfo } from '@rovit/tm-info'
import { TmAdvertisement } from '@rovit/tm-advertisement'
import { TmImage } from '@rovit/tm-image'
import { TmGallery } from '@rovit/tm-gallery'
import { TmMapShowcase } from '@rovit/tm-map-showcase'
import { TmGoogleStreetView } from '@rovit/tm-google-street-view'
import { TmVideo } from '@rovit/tm-video'
import { TmSketchFab } from '@rovit/tm-sketch-fab'
import { TmTrailForksTrailDetails } from '@rovit/tm-trail-forks-trail-details'
// import { TmRecommendedItems } from '@rovit/tm-recommended-items'

export default {
  name: 'Infobox',
  metaInfo: {
    title: 'Infobox'
  },
  components: {
    Dock,
    Scene,
    InfoboxToolbar
  },
  props: {
    env: {
      validator: val => true,
      default: null
    },
    scene: {
      validator: val => true,
      default: null
    }
  },
  setup(props, context) {
    const $store = inject('$store')
    // The index of the active scene module.
    const currentIndex = ref(-1)

    const dockStyle = ref({
      top: '-46px'
    })
    const content = ref(null)
    onMounted(() => {
      createObserver()
    })

    const authenticatedUser = computed(() => $store.state.auth.user)

    watch(
      () => props.scene,
      scene => {
        if (scene) {
          // Recording scene-views
          recordEvent('scene-view')

          setTimeout(() => {
            dockStyle.value.top = '-146px'
          }, 1000)
          setTimeout(() => {
            dockStyle.value.top = '-46px'
          }, 3000)
        }
      },
      { immediate: true }
    )

    /**
     * The SceneModule component library. Any components to be viewed in the Scene
     * must first be registered here.
     */
    const components = [
      TmServiceInfo,
      TmContactInfo,
      TmSchedule,
      TmDirections,
      TmLocationInfo,
      TmSceneTour,
      TmInfo,
      TmAdvertisement,
      TmImage,
      TmGallery,
      TmMapShowcase,
      TmGoogleStreetView,
      TmVideo,
      TmSketchFab,
      TmTrailForksTrailDetails
      // TmRecommendedItems
    ]
    const additionalProps = {
      all: {
        mapboxToken: context.root.$store.state.mapboxToken
      },
      TmServiceInfo: {},
      TmContactInfo: {},
      TmSchedule: {},
      TmDirections: {},
      TmLocationInfo: {},
      TmSceneTour: {},
      TmInfo: {},
      TmAdvertisement: {},
      TmImage: {},
      TmGallery: {},
      TmMapShowcase: {},
      TmTrailForksTrailDetails: {}
      // TmRecommendedItems: {}
    }

    /**
     * Handles the activate event from the current scene's modules.
     */
    function handleActivate(data) {
      const { module, index, meta } = data
      this.currentIndex = index
    }

    function handleLink(data) {
      const { module, index, meta } = data
      if (meta.link) {
        openMiniInfobox({ link: meta.link })
      }
    }

    watch(
      () => props.scene && props.scene._id,
      sceneId => {
        $store.commit('mapMain/setMarkedFeature', props.scene)
        // Scroll to the top of the page when opening a new scene.
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        })
      },
      { immediate: true }
    )

    function handleSaveClick(e) {
      /**
       * If user is not logged in, add that to storage and once he logs sync stored data with db
       */

      if (authenticatedUser.value) {
        saveToPlanner({
          service: props.scene.service,
          scene: props.scene,
          env: props.env,
          $toasted: this.$toasted
        })
      } else {
        addToPlanner({
          service: props.scene.service,
          scene: props.scene,
          env: props.env
        })

        $store.dispatch('planner/openPlanner')
      }
    }

    /**
     * Observer will trigger when page will scrolled to certain threshold
     */
    function createObserver() {
      let options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.3
      }

      const observer = new IntersectionObserver(handleIntersect, options)
      observer.observe(content.value.$el)
    }

    function handleIntersect([entry], observer) {
      const meta = {
        moduleId: null,
        moduleType: null
      }

      if (entry.isIntersecting && props.scene) {
        recordEvent('scene-module-impression', meta)
      }
    }

    function recordEvent(type, meta) {
      const metadata = {
        sourceEnvId: props.env && props.env._id,
        sourceEnvName: props.env && props.env.name,
        sceneId: props.scene && props.scene._id,
        sceneName: props.scene && props.scene.name
      }

      if (meta) {
        Object.assign(metadata, { ...meta })
      }

      $store.dispatch('analytics/logEvent', {
        type,
        [type]: metadata
      })
    }

    /**
     * To record component specific interactions
     */
    function handleEvent({ module }) {
      if (module) {
        const { componentName, _id } = module
        const meta = {
          moduleId: _id,
          moduleType: componentName
        }

        recordEvent('scene-module-interaction', meta)
      }
    }

    return {
      models,
      dockStyle,

      // Scene
      currentIndex,
      components,
      additionalProps,

      // Handlers
      handleActivate,
      handleLink,
      handleSaveClick,

      // History
      close,

      content,
      handleEvent
    }
  }
}
</script>

<style lang="postcss"></style>
