var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-row justify-between px-1 pt-1"},[_c('div',{staticClass:"flex flex-row items-center"},[_c('ToolbarIconButton',{attrs:{"icon":"ChevronLeftIcon"},nativeOn:{"click":function($event){return _vm.back($event)}}}),_c('p',{staticClass:"hidden pr-3 ml-2 whitespace-no-wrap md:inline sm:text-lg"},[_vm._v(_vm._s(_vm.title))])],1),_c('div',{staticClass:"flex flex-row items-center text-lg font-bold"},[_c('InfoboxSaveButton',{on:{"save-click":function (e) { return _vm.$emit('save-click', e); }}}),_c('PlannerShareButton',{ref:"socialSharingPopperTarget",nativeOn:{"click":function($event){$event.stopPropagation();return _vm.popper.toggle($event)}}}),_c('ToolbarIconButton',{staticClass:"hidden mr-1 md:flex",class:[
        _vm.isReadyToReadMore
          ? 'ready-to-read-more animate__animated animate__tada animate__repeat-2'
          : null
      ],attrs:{"icon":_vm.readMore ? 'ChevronDownIcon' : 'ChevronUpIcon',"text":_vm.readMore ? 'Read Less' : 'Read More'},nativeOn:{"click":function($event){return _vm.toggleReadMore($event)}}}),_c('ToolbarIconButton',{staticClass:"mr-1 md:hidden",class:[
        _vm.isReadyToReadMore
          ? 'ready-to-read-more animate__animated animate__tada animate__repeat-2'
          : null
      ],attrs:{"icon":_vm.readMore ? 'ChevronDownIcon' : 'ChevronUpIcon'},nativeOn:{"click":function($event){return _vm.toggleReadMore($event)}}})],1),_c('div',{staticClass:"flex flex-row items-center justify-end md:w-32"},[_c('ToolbarIconButton',{attrs:{"icon":"XIcon"},nativeOn:{"click":function($event){return _vm.close($event)}}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.popper.isVisible),expression:"popper.isVisible"},{name:"click-outside",rawName:"v-click-outside",value:(_vm.popper.close),expression:"popper.close"}],ref:"socialSharingPopper",staticClass:"absolute z-50 w-40 text-center bg-gray-100 dark:bg-gray-900 border border-gray-400 rounded shadow-lg"},[_c('SocialShare',{attrs:{"user":_vm.$store.state.auth.user,"url":_vm.currentUrl,"title":_vm.title || '',"description":_vm.description || '',"hashtags":"rovit","twitter-user":"rovit"},on:{"close":_vm.popper.close}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }